.movements {
    align-self: flex-start;
}

.balance {
    text-align: center;
    padding: 40px 10px;
}

.balance-value {
    font-size: 36px;
    color: white;
}

.periods-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.updated {
    text-align: right;
    padding-left: 10px;
    display: flex;
    align-items: center;
}

.table-responsive {
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
}

.table {
    margin-bottom: 0 !important;
}

.table .table-column-header {
    vertical-align: middle;
    padding: 8px;
}

.table .table-column {
    vertical-align: middle;
    padding: 5px 10px;
}

.table .column-1 {
    padding-left: 0;
    padding-right: 0;
}

.table .column-2 {
    text-align: right;
    white-space: nowrap;
}

.dropdown-select, .dropdown-select:focus {
    border: none;
    box-shadow: none;
}




