.app {
    display: flex;
    flex-direction: column;
    justify-items: center;
    height: 100vh;
}
.app-container {
    padding: 0 10px;
    height: 100vh;
    display: flex;
    justify-items: center;
    align-items: center;
    align-self: center;
    max-width: 500px;
    min-width: 320px;
}
.app-loading {
    position: absolute;
    z-index: 1;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #202226;
    width: 100%;
    height: 70px;
    color: white;
    padding: 10px;
    -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.75);
    -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,0.75);
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.75);
}

.app-header .btn {
    height: 50px;
}

.app-link {
    display: inline-block;
    cursor: pointer;
}

.logout-container {
    width: 85px;
    text-align: right;
}

.app-offline {
    position: absolute;
    width: 100%;
    top: 70px;
    background-color: red;
    text-align: center;
    color: white;
    font-size: 14px;
    opacity: 0.7;
}
