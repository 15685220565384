.form-signin {
    width: 300px;
    min-height: 300px;
}

.form-signin .form-signin-title {
    font-size: 26px;
    text-align: center;
    margin-bottom: 20px;
}

.form-signin .checkbox {
    font-weight: 400;
}
.form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}
.form-signin .form-control:focus {
    z-index: 2;
}
.form-signin input[type="text"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.form-signin .login-button {
    width: 150px;
    display: block !important;
    margin: 0 auto;
}

.form-signin .error {
    color: red;
    font-weight: bold;
    padding-top: 20px;
    font-size: 12px;
    text-align: center;
}
